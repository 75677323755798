import React from 'react';
import { Link } from 'gatsby';
import Styleswrapper from '../components/StylesWrapper';
import Mainheader from '../components/MainHeader';
import Footer from '../components/Footer';

import '../styles/404.css';

// icons

import MoonIcon from '../images/icons/moon.png';
import TabbyCatIcon from '../images/icons/tabby_cat.png';
import CrossedSwordsIcon from '../images/icons/crossed_swords.png';

const PageNotFound = () => {
  return (
    <Styleswrapper
      bgColor1="#081D4D"
      bgColor2="#00070D"
      bg1Stop="0%"
      bg2Stop="50%"
      noise="true"
    >
      <Mainheader
        bgColor1="#081D4D"
        bgColor2="#00070D"
        bg1Stop="0%"
        bg2Stop="35%"
        noise="true"
      />

      <section className="notfound-main">
        <div className="notfound-main-wrapper">
          <div className="moon-icon">
            <img src={MoonIcon}
              alt="🌙"
              draggable="false"
              loading='eager'
            />
          </div>

          <div className='main'>
            <h1>
              You've discovered a new space
            </h1>
            <h2>
              This is usually an error page but since you're here,
              might as well check out these recommendations
            </h2>
            <div className="notfound-links">

              <Link to="/work/aleyr" className='notfound-link'>
                <div className="icon">
                  <img src={TabbyCatIcon}
                    alt="🐈"
                    draggable="false"
                    loading='eager'
                  />
                </div>
                <span className="link-title">
                  Discovering the thin line between pet ownership and pet parenting
                </span>
                <span className="link-label">
                  Case Study
                </span>
              </Link>

              <Link to="/work/codezeros" className='notfound-link'>
                <div className="icon">
                  <img src={CrossedSwordsIcon}
                    alt="⚔️"
                    draggable="false"
                    loading='eager'
                  />
                </div>
                <span className="link-title">
                  Envisioning a 21st century workspace. For real!
                </span>
                <span className="link-label">
                  Case Study
                </span>
              </Link>

            </div>
          </div>
        </div>
      </section>

      <Footer />

    </Styleswrapper>
  );
}

export default PageNotFound;
